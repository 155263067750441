<template>

  <HowitWorks />

</template>

<script>

import HowitWorks from "@/components/view/HowitWorks";

export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
HowitWorks,
 
  },
  metaInfo: {
    title: "hoe werkt jouwbedrijfsmakelaar| jouwbedrijfsmakelaar Nederland ",
    metaInfo:'Op zoek naar een makelaar die gespecialiseerd is in het verkopen, verhuren en aankopen van bedrijfspanden ',
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>