<template>
    <div>
     <img src= @/assets/pages.png height="300" width="100%" />

<v-container>
    <section class="werkwijze">
<v-row>

<v-col  cols="7">

<h3> Onze werkwijze </h3>

<p>  Ben je op zoek naar een deskundige aankoopmakelaar Capelle aan den IJssel? Die op een professionele manier met je meedenkt? Dan zit je bij ons Makelaarskantoor goed. 
    Wij hebben jarenlange ervaring op de onroerend goed markt. Wij zorgen ervoor dat jouw pand onder de aandacht wordt gebracht</p>

    <h3> van A tot Z </h3>

    <p> Het is belangerijk dat je zonder zorgen jouw pand van kan verhuren of verkopen. Om die reden zorgen wij ook voor all contracten, bezichtigingen en onderhandelingen.
        Dit doen wij allemaal voor een <b> vast tarief</b>. Wij rekenen éénmalige  € 950,- excl. BTW voor het verhuurpakket en € 1.050,- excl. BTW voor het verkooppakket.</p>

</v-col>
<v-card
    class="mx-auto"
    max-width="400"
      elevation="9"
  shaped
  >
    <v-img
      class="white--text align-end"
      height="300px"
      src="@/assets/marketing.jpg"
    >
    
    </v-img>

  </v-card>
<v-col>


</v-col>

    </v-row>
   </section>

    <v-row class="aanpak">
<v-col>
<h3> Wat maakt onze aanpak uniek </h3>

<v-timeline
    align-top
    :dense="$vuetify.breakpoint.smAndDown"
  >
    <v-timeline-item
      v-for="(item, i) in items"
      :key="i"
      :color="item.color"
      :icon="item.icon"
      fill-dot
    >
      <v-card
        :color="item.color"
        dark
      >
        <v-card-title class="text-h6">
  {{item.title}}
        </v-card-title>
        <v-card-text class="white text--primary">
          <p>{{item.text}}</p>
          <v-btn
            :color="item.color"
            class="mx-0"
            outlined
          >
             <router-link to="/Contact">
                      Neem contact op</router-link
              >

          </v-btn>
        </v-card-text>
      </v-card>
    </v-timeline-item>
 </v-timeline>
</v-col>
    </v-row>

 
</v-container>
    </div>

</template>

<script>
  export default {
    data: () => ({
      items: [
        {
          color: 'red lighten-2',
          icon: 'mdi-star',
          title:"Jij houdt zelf de regie",
          text:"Door zelf de bezichtigingen uit te voeren maakt je direct kennis met kandidaten en niet pas tijdens het zetten van de handtekeningen. ",
        },
        {
          color: 'purple darken-1',
          icon: 'mdi-book-variant',
        title:"Marketing",
          text:"Wij zorgen ervoor dat jouw pand op alle platformen staat",
        },
        {
          color: 'green lighten-1',
          icon: 'mdi-airballoon',
               title:"Contract procedure",
          text:"Wij stellen de contracten op en doen de onderhandelingen,indien gewenst. ",
        },
        {
          color: 'indigo',
          icon: 'mdi-buffer',
        title:"Ondersteuning",
          text:"Toch nog vragen? Geen probleem! Ons team staat altijd voor jou klaar. ",
        },
      ],
    }),
  }
</script>

<style scoped>
.werkwijze{
    margin-top:20px;
}
.aanpak{
    margin-top:50px;
}

.aanpak h3{
    text-align: center;
}
</style>